import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { InternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/ui/smart-link';
import { PageContainer } from '@shared/ui/page-container';
import FranchiseImage from './assets/franchise.png';

function Franchise() {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Helmet title={t('Franchise.franchise')} />
      <SmartLink href="https://get.sushiwok.ru/?utm_source=sushiwok_ru&utm_medium=banner">
        <InternalImage
          src={FranchiseImage}
          alt={t('Franchise.franchise')}
          width={1000}
          height={500}
        />
      </SmartLink>
    </PageContainer>
  );
}

export default Franchise;
