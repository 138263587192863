import React, { Component } from 'react';
import globalStore from 'modules/global-store';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/page-container';
import { PageTitle } from '@shared/ui/page-title';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import CustomHelmet from '../../Components/CustomHelmet';
import styles from './staticPage.module.scss';

class StaticPage extends Component {
  static LOADED_KEY = 'static_page_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const { static_id: text_id } = params;
    return fetch('static_page', { text_id })
      .then((result) => {
        if (!result) {
          globalStore.set('is404', true);
        }
        globalStore.set('static_page', result);
      })
      .catch((e) => {
        console.error('StaticPage', e);
        return '';
      });
  };

  constructor(props) {
    super(props);

    this.state = {
      page: globalStore.get('static_page'),
    };
  }

  componentDidMount() {
    if (globalStore.get(StaticPage.LOADED_KEY)) {
      globalStore.unlink(StaticPage.LOADED_KEY);
    } else {
      StaticPage.initialData(api, this.props.match.params, globalStore).then(
        () => {
          this.setState({
            page: globalStore.get('static_page'),
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.static_id === this.props.match.params.static_id
    ) {
      return false;
    }
    StaticPage.initialData(api, this.props.match.params, globalStore).then(
      () => {
        this.setState({
          page: globalStore.get('static_page'),
        });
      }
    );
  }

  render() {
    const { page } = this.state;
    if (!page) return null;
    if (page && page.length === 0) {
      return <ErrorComponent />;
    }

    const { title, description } = page[0];
    return (
      <PageContainer>
        <CustomHelmet title={title} />
        <Breadcrumbs
          links={[
            {
              text: title,
            },
          ]}
        />
        <PageTitle isCenterOnMobile>{title}</PageTitle>
        <div
          className={styles.mainContainer}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </PageContainer>
    );
  }
}

export default ssr(StaticPage);
