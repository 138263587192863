import { useState } from 'react';
import { useResizeWindow } from 'frontend/hooks';
import { ExternalImage } from '@shared/ui/image';
import { useSelectCountryMainDomain } from '@entities/countries';
import styles from './styles.module.scss';

interface IBanner {
  src: string;
  link: string;
  /*   device: 'iPhone' | 'android'; */
  alt?: string;
}
function Banner({ alt = '', src, link }: IBanner) {
  const [visible, setVisible] = useState(
    !localStorage.getItem('isVisibleBanner')
  );
  const mainDomain = useSelectCountryMainDomain();

  const size = useResizeWindow();

  const closeBanner = () => {
    setVisible(false);
    localStorage.setItem('isVisibleBanner', 'true');
  };

  if (!size.isMobile || !src || !visible) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <a href={link} /* onClick={clickBanner} */>
        <ExternalImage
          mainDomain={mainDomain}
          alt={alt}
          src={`/${src}`}
          width={430}
          height={230}
        />
      </a>
      <span className={styles.closeButton} onClick={closeBanner} />
    </div>
  );
}

export default Banner;
