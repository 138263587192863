import { Helmet } from 'react-helmet-async';
import * as session from 'modules/session';
import { PageContainer } from '@shared/ui/page-container';

const getInformation = () => {
  const isWindowExist = typeof window !== 'undefined';
  if (!isWindowExist) return null;
  const { order: { organizationContactInformation } = {} } =
    session.get('cart');
  return organizationContactInformation;
};

function OrganizationContactInformation() {
  const organizationContactInformation = getInformation();

  return (
    <PageContainer>
      <Helmet title="Юридическое лицо" />
      {organizationContactInformation && (
        <div
          dangerouslySetInnerHTML={{ __html: organizationContactInformation }}
        />
      )}
    </PageContainer>
  );
}

export default OrganizationContactInformation;
