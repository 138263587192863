import { useEffect, useState } from 'react';
import { setAvailable } from '@entities/complectation/model/slice';
import { useAppDispatch } from '@shared/lib/store';
import { useTranslation } from 'react-i18next';
import { getProducts } from '@entities/product/lib/useGetProducts';
import { IProduct } from 'types/product';
import { BlockContainer } from '@shared/ui/block-container';
import { ScrollAnchor } from '@shared/ui/scroll-anchor/ScrollAnchor';
import { Subtitle } from '@shared/ui/subtitle';
import * as session from 'modules/session';
import { ICart } from 'types/cartTypes';
import ComplectationFree from '../complectation-free/ComplectationFree';
import ComplectationPaid from '../complectation-paid/ComplectationPaid';
import styles from './styles.module.scss';
import { isThereFree } from '../../lib';
import { complectationAnchor } from '../../model/constants';
import { InfoButton } from '../info-btn/InfoButton';

function ComplectationRoot({ infoBtnAction }: { infoBtnAction: () => void }) {
  const { complectations }: ICart = session.get('cart');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    if (!complectations?.length) return;
    const ids = complectations.map(({ id }) => id);
    getProducts(ids).then((prods) => {
      setProducts(prods);
      const filtered = complectations.filter((item) =>
        prods.find(({ id }) => id === item.id)
      );

      dispatch(setAvailable(filtered));
    });
  }, [complectations, dispatch]);

  if (!complectations?.length) return null;
  if (!products?.length) return null;

  const isFree = isThereFree(complectations);

  return (
    <BlockContainer>
      <header className={styles.header}>
        <ScrollAnchor
          name={complectationAnchor}
          className={styles.titleContainer}
        >
          <Subtitle>{t('Complectation.complectation')}</Subtitle>
          <InfoButton action={infoBtnAction} />
        </ScrollAnchor>
        <p className={styles.caption}>
          {t(isFree ? 'Complectation.freeAndPaid' : 'Complectation.onlyPaid')}
        </p>
      </header>

      {isFree && <ComplectationFree products={products} />}
      <ComplectationPaid products={products} />
    </BlockContainer>
  );
}

export default ComplectationRoot;
