import './BlockMap.scss';
import { useRef, useEffect } from 'react';
import * as cartHelper from 'modules/helpers/cart-helper';
import { route } from 'modules/route';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import { historyEmitter } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import { setSelectedStoreId } from '@entities/pickup';
import { useAppDispatch } from '@shared/lib/store';
import { IStore } from '@entities/stores';
import { useHistory } from 'react-router-dom';
import { scrollToExecution, isOnlyForDelivery } from '../../Helpers';
import Emitter from '../../Emitters';
import PickupMap from './PickupMap/PickupMap';

function BlockMap() {
  const history = useHistory();
  const { t } = useTranslation();
  const blockMap = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    scrollToExecution(blockMap.current, 100);

    if (isOnlyForDelivery()) {
      history.replace(route('cart'));
    }
  }, []);

  const selectStore = (store: IStore) => {
    if (store) {
      const cart = session.get('cart');
      const product_ids = cartHelper.getActiveProductIds(cart);

      dispatch(setSelectedStoreId(store.id));

      api('store.get_stopped', {
        store_id: store.id,
        product_ids,
      })
        .then((stopped_products = []) => {
          if (stopped_products.length) {
            Emitter.emit('MODAL_CALL', {
              modal: 'StopListsModal',
              value: { product_ids: stopped_products },
            });
          } else {
            historyEmitter.emit(
              'HISTORY_PUSH',
              route(`/cart/pickup/${store.latitude}_${store.longitude}`)
            );
          }
        })
        .catch((error) => {
          console.error(error);

          Emitter.emit('MODAL_CALL', {
            modal: 'ErrorCartModal',
            value: t('BlockMap.something_went_wrong'),
          });
        });
    }
  };

  useEffect(() => {
    Emitter.addListener('SELECT_STORE', selectStore);
    scrollToExecution(blockMap.current, 100);

    if (isOnlyForDelivery()) {
      history.replace(route('cart'));
    }

    return () => {
      Emitter.removeListener('SELECT_STORE', selectStore);
    };
  }, []);

  return (
    <div className="block-map" ref={blockMap}>
      <div className="block-map__title-container">
        <h2>{t('BlockMap.selectAStoreforPickup')}</h2>
      </div>
      <PickupMap onSelectStore={selectStore} />
    </div>
  );
}

export default BlockMap;
