import { useTranslation } from 'react-i18next';
import { ExternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/ui/smart-link';
import { IPreparetedOrderItem, ITopping } from '../../model/types';
import styles from './activeOrderItem.module.scss';

interface IActiveOrderItemProps {
  item: IPreparetedOrderItem;
  currency: string;
  mainDomain: string;
}

function ActiveOrderToppings({ toppings }: { toppings: ITopping[] }) {
  const { t } = useTranslation();

  if (!toppings.length) return null;

  return (
    <p className={styles.topping}>
      {t('OrderComponent.withToppings')}:{' '}
      {toppings.map((topping, index) => {
        const toppingTitle = topping.title.trim().toLowerCase();
        const qty = topping.qty > 1 ? ` x${topping.qty}` : '';
        const comma = index !== toppings.length - 1 ? ', ' : '';

        return `${toppingTitle}${qty}${comma}`;
      })}
    </p>
  );
}

export function ActiveOrderItem({
  item,
  currency,
  mainDomain,
}: IActiveOrderItemProps) {
  const { t } = useTranslation();
  const { toppings = [], productUrl, title, price, qty, images } = item;

  const toppingsPrice = toppings.reduce((a, b) => {
    return a + b.price * b.qty;
  }, 0);
  const totalPrice = `${price + toppingsPrice} ${currency}`;
  const imageUrl = `${images?.[0]?.filename}/80x80`;

  return (
    <SmartLink href={productUrl} className={styles.root} target="_blank">
      <ExternalImage
        mainDomain={mainDomain}
        src={imageUrl}
        width={80}
        height={80}
        alt={item.title || t('OrderComponent.cart_block_preview_content')}
        className={styles.img}
      />
      <div className={styles.list}>
        <div className={styles.titleBlock}>
          <p className={styles.title}>{title}</p>
          <ActiveOrderToppings toppings={toppings} />
        </div>
        <div className={styles.count}>x {qty}</div>
        <div className={styles.price}>
          {!price ? t('OrderComponent.free') : totalPrice}
        </div>
      </div>
    </SmartLink>
  );
}
