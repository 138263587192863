import { Component } from 'react';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { LinkButton } from '@shared/ui/button';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageTitle } from '@shared/ui/page-title';
import { PageContainer } from '@shared/ui/page-container';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import { EventSingle } from '../../Components/EventSingle/EventSingle';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';
import './OneNews.scss';

class NewsPage extends Component {
  static LOADED_KEY = 'newspage_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const { news_item: text_id } = params;
    return fetch('news.get', { text_id })
      .then((result) => {
        if (!result || !Array.isArray(result) || !result[0]) {
          globalStore.set('is404', true);
          globalStore.set('news', false);
          return false;
        }
        globalStore.set('news', result[0]);
      })
      .catch((e) => {
        console.error('NewsPage', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    const news = globalStore.get('news', false);
    this.state = {
      news,
      notFound: false,
    };
    if (!news) {
      globalStore.put('is404', true, true);
    }
  }

  componentDidMount() {
    const { news } = this.state;
    if (globalStore.get(NewsPage.LOADED_KEY)) {
      globalStore.unlink(NewsPage.LOADED_KEY);
      this.setState({
        notFound: !news,
      });
    } else {
      NewsPage.initialData(api, this.props.match.params, globalStore).then(
        () => {
          const news = globalStore.get('news', false);
          this.setState({
            news,
            notFound: !news,
          });
        }
      );
    }
    if (globalStore.get('news', false)) {
      globalStore.put('is404', undefined, true);
    }
  }

  componentWillUnmount() {
    globalStore.set('news', null);
  }

  getTitle(news) {
    if (news) {
      const { title = '' } = news;
      return title;
    }
    return '';
  }

  render() {
    const { news = {}, notFound } = this.state;
    const { t } = this.props;
    if (notFound) {
      return (
        <ErrorComponent
          action_link="/news"
          action_text={t('news.newsGetAll')}
        />
      );
    }

    const { name = '', images = [] } = news || {};
    const isLoad = !!Object.keys(news).length;

    return (
      <PageContainer>
        <CustomHelmet
          title={this.getTitle(news)}
          type="news"
          image={`${session.get('protocol')}://${session.get('location')}/img/${
            images[0] && images[0].filename
          }`}
          ogTitle={this.getTitle(news)}
          ogDescription={news.description_mobile}
        />
        <Breadcrumbs
          links={[
            {
              href: '/news',
              text: t('news.newsTitle'),
            },
            {
              text: name,
            },
          ]}
        />
        <PageTitle>{name}</PageTitle>
        <div
          className="event"
          itemScope
          itemType="http://schema.org/NewsArticle"
        >
          <EventSingle content={news} />
        </div>
        {isLoad ? (
          <div className="linkBtnContainer">
            <LinkButton href="/news">{t('news.newsGetAll')}</LinkButton>
          </div>
        ) : undefined}
        <CustomText />
      </PageContainer>
    );
  }
}

export default ssr(NewsPage);
