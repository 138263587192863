import { LinkButton, Button } from '@shared/ui/button';
import { useTranslation } from 'react-i18next';
import { ModalParagraph } from '@shared/ui/modal-paragraph';
import styles from './errorCartModal.module.scss';
// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';
import Emitter from '../../../Emitters';

interface IErrorCartModal {
  value: string;
  info: string;
  buttons: any[];
  links: any;
}

function ErrorCartModal({ info, buttons, links, value }: IErrorCartModal) {
  const { t } = useTranslation();
  const confirm = () => {
    Emitter.emit('MODAL_CALL', { modal: false });
  };
  const newValue = info ? `${value}*` : value;

  return (
    <div className={styles.errorModal}>
      <ModalParagraph>{newValue}</ModalParagraph>
      {info && (
        <div className={modalStyles.subtitleContainer}>
          <p className={styles.info}>*{info}</p>
        </div>
      )}
      <div className={modalStyles.btnContainer}>
        {links && (
          <LinkButton isFullWidth href={links.href} onClick={confirm}>
            {links.text}
          </LinkButton>
        )}
        {buttons.length > 0 ? (
          buttons.map((button: { text: string }) => {
            return (
              <Button key={button.text} onClick={confirm}>
                {button.text}
              </Button>
            );
          })
        ) : (
          <Button onClick={confirm}>{t('locale.well')}</Button>
        )}
      </div>
    </div>
  );
}

export default ErrorCartModal;
