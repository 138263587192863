import './VacancyCardMobile.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import { ExternalImage } from '@shared/ui/image';
import { connect } from 'react-redux';
import { SmartLink } from '@shared/ui/smart-link';

class VacancyCardMobile extends Component {
  @autobind
  showVacanciesFormModal(event) {
    event.stopPropagation();
    const {
      vacancy: { id },
    } = this.props;
    modalEmitter.emit('Vacancies.Modal.Open', id);
  }

  render() {
    const { vacancy, mainDomain, t } = this.props;
    const { text_id, title, city_name, image, salary, schedule, experience } =
      vacancy;

    return (
      <div className="vacancy__card">
        <SmartLink
          href={`/vakansii/${text_id}`}
          className="vacancy__card__front"
        >
          <div className="vacancy__card__head">
            <h2 className="vacancy__card__title">{title}</h2>
            <div className="vacancy__card__city"> {city_name}</div>
          </div>
          <ExternalImage
            mainDomain={mainDomain}
            src={image}
            width={450}
            height={300}
            className="vacancy__card__image"
          />
          <div className="vacancy__card__info">
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Salary')}:
              </div>
              <div className="vacancy__card__info__row__value">{salary}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Schedule')}:
              </div>
              <div className="vacancy__card__info__row__value">{schedule}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Experience')}:
              </div>
              <div className="vacancy__card__info__row__value">
                {experience}
              </div>
            </div>
          </div>
        </SmartLink>
        <div className="vacancy__card__button_container">
          <Button
            className="vacancy__card__button"
            onClick={this.showVacanciesFormModal}
          >
            {t('localization.Send resume')}
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(withTranslation()(VacancyCardMobile));
