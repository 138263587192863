import '../../Components/NewCartOrder/NewCartOrder.scss';
import '../../Components/NewCartOrder/NewCartOrderMobile.scss';
import { Component } from 'react';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import UserGifts from 'frontend/Components/UserGifts/UserGifts';
import { analytics } from 'frontend/analytics/analytics';
import { getFbPixelDataToPurchase } from 'frontend/analytics/analyticsHelper';
import MinAmountMessage from 'frontend/Components/cart/min-amount-message/MinAmountMessage';
import {
  isOnlyForDelivery,
  isOnlyForSelf,
} from 'frontend/Components/NewCartOrder/Helpers';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import Empty from 'frontend/Components/NewCartOrder/Components/Empty/Empty';
import BlockProducts from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/CartProductsBlock';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import CustomText from 'frontend/Components/CustomText/CustomText';
import CustomHelmet from 'frontend/Components/CustomHelmet';
import { FreeDeliveryWarning } from '@entities/delivery';
import { connect } from 'react-redux';
import { PlaceOrder } from '@widgets/place-order';
import { PageContainer } from '@shared/ui/page-container';
import { Subtitle } from '@shared/ui/subtitle';
import { BlockContainer } from '@shared/ui/block-container';
import { RoutesWithSubRoutes } from '@shared/lib/routes-with-sub-routes';
import PromoPhoneForm from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/PromoPhoneForm';
import styles from './cart.module.scss';

class Cart extends Component {
  static LOADED_KEY = 'cart_loaded';

  static initialData(fetch, params = {}, globalStore) {
    const { skipAdditional } = params || {};
    const promises = [];
    if (!skipAdditional) {
      promises.push(
        fetch('cart.get_additionally')
          .then((products) => globalStore.set('additionally', products))
          .catch((e) => {
            console.error('NewCart 2', e);
            return [];
          })
      );
    }

    return Promise.all(promises)
      .then(() => [])
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  constructor(props) {
    super(props);

    this.state = {
      isPickupProducts: isOnlyForSelf(),
      isDeliveryProducts: isOnlyForDelivery(),
      isNotEmptyUserGifts: true,
      sessionUserGifts: session.get('userGifts', []),
    };
  }

  componentDidMount() {
    Emitter.addListener('CART_CHANGE', this.cartChange);
    Emitter.addListener('USER_GIFTS_CHANGE', this.onUserGiftsChange);
    analytics.fbPixel(
      'InitiateCheckout',
      getFbPixelDataToPurchase({
        countryCurrency: this.props.countryCurrency,
        cart: session.get('cart'),
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sessionUserGifts !== this.state.sessionUserGifts) {
      this.setState({
        isPickupProducts: isOnlyForSelf(),
        isDeliveryProducts: isOnlyForDelivery(),
      });
    }
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.cartChange);
    Emitter.removeListener('USER_GIFTS_CHANGE', this.onUserGiftsChange);
  }

  onUserGiftsChange = () => {
    this.setState({ sessionUserGifts: session.get('userGifts', []) });
  };

  cartChange = () => {
    Cart.initialData(api, { skipAdditional: true }, globalStore);

    Cart.initialData(api, { skipAdditional: true }, globalStore)
      .then(() => {
        this.setState({
          isPickupProducts: isOnlyForSelf(),
          isDeliveryProducts: isOnlyForDelivery(),
          sessionUserGifts: session.get('userGifts', []),
        });
      })
      .catch((e) => console.error(e));
  };

  setIsNotEmptyUserGifts = (isNotEmptyUserGifts) => {
    this.setState({ isNotEmptyUserGifts });
  };

  render() {
    const { isPickupProducts, isDeliveryProducts, isNotEmptyUserGifts } =
      this.state;
    const { match: { isExact } = {}, t, routes } = this.props;

    const { items = [], total } = session.get('cart') || {};
    const {
      free_order_cost,
      delivery: { order_min },
    } = globalStore.get('city_info');

    const isItems = !!items.length;

    return (
      <PageContainer>
        <CustomHelmet title={t('NewCart.cart')} />
        <Breadcrumbs
          links={[
            {
              text: t('NewCart.cart'),
            },
          ]}
        />
        <div className={styles.cart}>
          {isItems ? (
            <>
              <MinAmountMessage total={total} orderMin={order_min} />
              {isExact && <PromoPhoneForm />}
              <BlockProducts />
              <RoutesWithSubRoutes routes={routes} />
              {isExact && (
                <>
                  <FreeDeliveryWarning
                    total={total}
                    orderMin={order_min}
                    freeOrderCost={free_order_cost}
                  />
                  <PlaceOrder
                    isPickupProducts={isPickupProducts}
                    isDeliveryProducts={isDeliveryProducts}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Empty />
              {isNotEmptyUserGifts && (
                <BlockContainer>
                  <Subtitle>{t('BlockProducts.user_gift')}</Subtitle>
                  <p className="classToParagraph">
                    {t('BlockProducts.userGiftDescription')}
                  </p>
                  <UserGifts
                    isExact={isExact}
                    setIsNotEmptyUserGifts={this.setIsNotEmptyUserGifts}
                  />
                </BlockContainer>
              )}
            </>
          )}
        </div>
        <CustomText />
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ countries }) => ({
  countryCurrency: countries.country.currency,
});

export default connect(mapStateToProps)(ssr(Cart));
