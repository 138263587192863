import { Promocode } from '@entities/promocode';
import { TFunction } from 'i18next';
import { Phone } from '../BlockForm/Fields/Phone';

export const userBonusFields = ({
  isMontenegro,
  t,
}: {
  isMontenegro: boolean;
  t: TFunction;
}) => [
  {
    Component: isMontenegro ? null : Phone,
    type: 'phone',
    name: 'phone',
    required: true,
    placeholder: t('locale.phone'),
    pickup: true,
    isBonusPhone: true,
  },
  {
    Component: Promocode,
    name: 'promocode',
    type: 'text',
    label: 'promocode',
    button: true,
    pickup: true,
    fieldsetClassName: 'one-in-row',
    required: false,
  },
];
