import { clsx } from 'clsx';
import { SmartLink } from '@shared/ui/smart-link';
import loadable from '@loadable/component';
import { useIntervalIndex } from '../../model/useIntervalIndex';
import { INTERVAL } from '../../config/constants';
import styles from './DesktopMenu.module.scss';
import type { IMenuElement } from '../../model/types';

const Icon = loadable(() => import('../icon/Icon').then(({ Icon: I }) => I), {
  ssr: false,
});

export function DesktopMenu({
  mainDomain,
  menuList,
}: {
  mainDomain: string;
  menuList: IMenuElement[];
}) {
  const menu = menuList.filter(({ visible }) => visible);
  const animatedIndex = useIntervalIndex(menuList.length, INTERVAL);
  return (
    <div className={styles.root}>
      {menu.map((item, index) => {
        return (
          <SmartLink
            href={`/menu/${item.text_id}`}
            className={clsx(styles.box, {
              [styles.iconAnimated]: index === animatedIndex,
            })}
            activeClassName={styles.iconActive}
            key={item.text_id}
          >
            <Icon item={item} mainDomain={mainDomain} />
            <div className={styles.iconDescr}>
              <span className={styles.iconDescrTitle}>{item.title}</span>
            </div>
          </SmartLink>
        );
      })}
    </div>
  );
}
