import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OnlinePayment, useGetOrderQuery } from '@entities/order';
import { OrderInfo } from '@widgets/order-info';
import { useQuery } from '@shared/lib/query';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/page-container';
import ErrorComponent from '../../../Components/ErrorComponent/ErrorComponent';
import CustomHelmet from '../../../Components/CustomHelmet';

type Params = {
  city: string;
  order_hash: string;
  order_salt: string;
};

function OrderInfoPage() {
  const { t, i18n } = useTranslation();
  const { order_hash, order_salt, city } = useParams<Params>();
  const { data, error, isFetching } = useGetOrderQuery({
    order_hash,
    order_salt,
    city,
    lang: i18n.language,
  });
  const params = useQuery();

  if (isFetching) {
    return null;
  }
  if (error) {
    return <ErrorComponent />;
  }
  if (!data?.data?.order) {
    return null;
  }

  const { order } = data.data;
  const isOnlinePayment = order.payment_type === 'co';
  const isOnlinePaymentStatus =
    order.payment_status === null || order.payment_status === 2;

  const isStartOnlinePaymentProcess =
    isOnlinePayment &&
    isOnlinePaymentStatus &&
    !params.has('afterOnlinePayment'); // payment status doesn't have time to change
  const pageName = t('OrderInfoPage.delivery_title');
  return (
    <PageContainer>
      <CustomHelmet title={pageName} noindex />
      <Breadcrumbs
        links={[
          {
            text: pageName,
          },
        ]}
      />
      {isStartOnlinePaymentProcess ? (
        <OnlinePayment order_hash={order_hash} />
      ) : (
        <OrderInfo order={order} order_salt={order_salt} />
      )}
    </PageContainer>
  );
}

export default OrderInfoPage;
