import './Arguments.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Arguments1Image from 'assets/images/arguments/1.png';
import { SmartLink } from '@shared/ui/smart-link';
import { useSelectCity } from '@entities/cities';
import htmlParser from '@shared/lib/html-parser';
import getConfig from './config';
import DynamicImageArguments from './DynamicImageArguments';
import Background from './Background';

function Arguments() {
  const { t } = useTranslation();
  const city = useSelectCity();
  const isUa = city.lang === 'ua';
  const config = getConfig(t, isUa);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    const args = document.querySelectorAll('.arguments__img-container');

    const animations = document.querySelectorAll('animateMotion');
    const svg = document.querySelector('svg.bgr');

    timer.current = setTimeout(() => {
      Array.prototype.forEach.call(args, (arg, index) => {
        arg.classList.add('active');
        setTimeout(() => {
          arg.classList.add('is-hover');
        }, config.items[index].timer);

        arg.addEventListener(
          'click',
          ({ currentTarget }: { currentTarget: HTMLElement }) => {
            currentTarget.classList.add('is-hover');
          }
        );
      });
      Array.prototype.forEach.call(animations, (item) => {
        item.beginElement();
      });
      if (svg) {
        svg.classList.add('active');
      }
    }, 1000);

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [config.items]);

  return (
    <div className="arguments">
      <div className="arguments__wrapper">
        <div className="arguments__img-box">
          <div className="arguments__svg">
            <Background />
          </div>
          {config.items.map((item, index) => {
            return (
              <div
                className={`arguments__img-container ${item.number}`}
                key={item.number}
              >
                <DynamicImageArguments
                  className="callout"
                  urlChunk={`${index + 1}/1`}
                  alt={t('Arguments.line')}
                />

                <DynamicImageArguments
                  className="js-img-hover"
                  urlChunk={`${index + 1}/2`}
                  alt={t('Arguments.line')}
                />
                <div className="text-container">
                  <SmartLink
                    href={item.link.href}
                    className="text-container__link"
                  />
                  <h3>{htmlParser(item.title)}</h3>
                  {item.text &&
                    item.text.map((p, i) => (
                      <p className={`text-${i + 1}`} key={p}>
                        {htmlParser(p)}
                      </p>
                    ))}
                  {item.list && (
                    <ul className="">
                      {item.list.map((text) => (
                        <li key={text}>{htmlParser(text)}</li>
                      ))}
                    </ul>
                  )}
                  <div className="link-container">{item.link.text}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="arguments__title">
          <img src={Arguments1Image} alt={t('Arguments.mike')} />
          <h2>{t('Arguments.h2')}</h2>
        </div>
      </div>
    </div>
  );
}

export default Arguments;
