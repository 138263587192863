import { useEffect, useRef, useState } from 'react';
import Scroll from 'react-scroll/modules';
import { CloseButton } from '@shared/ui/close-button';
import { ResetCartModal } from '@features/cart-reset';
import { useHistory } from 'react-router-dom';
import PromocodeCartModal from '../Components/promocode-modal/PromocodeCartModal';
import ErrorCartModal from '../Components/error-cart-modal/ErrorCartModal';
import RegisterOrAuth from '../Components/register-auth-modal/RegisterOrAuth';
import PaymentModal from '../Components/payment-modal/PaymentModal';
import StopListsModal from '../Components/stop-lists-modal/StopListsModal';
import DisabledProductsModal from '../Components/disabled-products-modal/DisabledProductsModal';
import CardModal from '../../../Modals/CardModal/CardModal';
import NeedPromocodeModal from '../../../Modals/NeedPromocodeModal/NeedPromocodeModal';
import AuthModal from '../../../Modals/AuthModal/AuthModal';
import Emitter from '../../Emitters';
import DeleteUserModal from '../Components/delete-user-modal/DeleteUserModal';
import AfterDeleteUserModal from '../Components/after-delete-user-modal/AfterDeleteUserModal';
import styles from './styles.module.scss';

const modals = {
  ResetCartModal,
  PromocodeCartModal,
  NeedPromocodeModal,
  ErrorCartModal,
  RegisterOrAuthModal: RegisterOrAuth,
  PaymentModal,
  StopListsModal,
  AuthModal,
  CardModal,
  DeleteUserModal,
  AfterDeleteUserModal,
  DisabledProductsModal,
};

function MainCartModal(props) {
  const history = useHistory();
  const scrollTop = window.scrollY;
  const [disabled, setDisabled] = useState();
  const { tag, value, info, buttons, links } = props;

  const TagName = modals[tag];
  const isModal = !!tag;
  const modal = useRef(null);

  const prohibit = (disable) => {
    setDisabled(disable);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    Emitter.addListener('DO_NOT_CLOSE_MODAL', prohibit);

    return () => {
      document.body.style.overflow = 'unset';
      Emitter.removeListener('DO_NOT_CLOSE_MODAL', prohibit);
    };
  }, []);

  const handleClick = ({ target }) => {
    if (disabled) return;
    if (target.className === styles.mainCartModalContainer) {
      Scroll.animateScroll.scrollTo(scrollTop, {
        duration: 0,
      });
      Emitter.emit('MODAL_CLOSE', { modal: tag });
    }
  };
  return (
    <div
      className={styles.mainCartModalWrapper}
      onClick={(e) => handleClick(e)}
      onKeyDown={(e) => handleClick(e)}
      role="presentation"
      aria-hidden={isModal}
    >
      <div className={styles.mainCartModalContainer} ref={modal}>
        <div className={styles.withCloseBtnWrapper}>
          <CloseButton
            onClick={() => Emitter.emit('MODAL_CLOSE', { modal: tag })}
          />
          {isModal && <TagName {...{ value, info, buttons, history, links }} />}
        </div>
      </div>
    </div>
  );
}

export default MainCartModal;
