import { ReactNode, Ref, forwardRef } from 'react';
import { clsx } from 'clsx';
import styles from './cardWrapper.module.scss';

interface ICardWrapperProps {
  children: ReactNode;
  className?: string;
}

const CardWrapper = forwardRef(
  (props: ICardWrapperProps, ref: Ref<HTMLDivElement>) => {
    const { children, className } = props;

    const cardWrapperStyles = clsx(className, styles.cardWrapper);

    return (
      <div className={cardWrapperStyles} ref={ref}>
        {children}
      </div>
    );
  }
);

export default CardWrapper;
