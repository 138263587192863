import { PageContainer } from '@shared/ui/page-container';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';

function ErrorPage() {
  return (
    <PageContainer>
      <ErrorComponent />
    </PageContainer>
  );
}

export default ErrorPage;
