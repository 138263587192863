import { productIds } from '@shared/config/product-ids';
import { IUserOrderItem } from './types';

export function getProductsIds(items: IUserOrderItem[]) {
  const itemProducts = items.filter(
    (item) => !item.parent_id && item.product_id !== productIds.sticks
  );

  return itemProducts.map((item) => item.product_id);
}
