import { Component } from 'react';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import globalStore from 'modules/global-store';
import queryString from 'query-string';
import { FilterTags } from '@features/filter-tags';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/page-container';
import { PageTitle } from '@shared/ui/page-title';
import ArticleCard from './ArticleCard/ArticleCard';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';
import styles from './articles.module.scss';

const LIMIT = 4;

class Articles extends Component {
  static LOADED_KEY = 'articles_loaded';

  static initialData(fetch, params = {}, globalStore) {
    const { tag, page = 0 } = params;
    return Promise.all([
      fetch('articles.list', { limit: LIMIT, page, tag }).then((result) => {
        if (!Array.isArray(result)) {
          result = [];
        }
        return globalStore.set('articles_list', result);
      }),
      fetch('articles.categories').then((result) =>
        globalStore.set('articles_categories', result)
      ),
    ])
      .then(() => [])
      .catch((e) => {
        console.error('Articles', e);
        return [];
      });
  }

  static isLast(entities = []) {
    if (!Array.isArray(entities)) {
      entities = [];
    }
    return entities.some((n) => n.last);
  }

  constructor(props) {
    const query = queryString.parse(props.location.search || '');
    const { tag } = query;

    super(props);

    this.state = {
      tag,
      page: 0,
      likes: globalStore.get('articles_likes'),
      articles_list: globalStore.get('articles_list'),
      articles_categories: globalStore.get('articles_categories'),
    };
  }

  componentDidMount() {
    if (globalStore.get(Articles.LOADED_KEY)) {
      globalStore.unlink(Articles.LOADED_KEY);
    } else {
      const query = queryString.parse(this.props.location.search || '');
      const { tag } = query;
      Articles.initialData(api, { tag }, globalStore).then(() => {
        this.setState({
          articles_list: globalStore.get('articles_list', []),
          articles_categories: globalStore.get('articles_categories'),
        });
      });
    }
  }

  changeQuery = (newTag = null) => {
    let { tag } = this.state;
    if (newTag === tag) {
      return;
    }
    tag = newTag;
    this.setState({ tag, page: 0 });
    globalStore.set('articles_list', []);
    this.updateData(tag);
  };

  next = () => {
    let { page = 0 } = this.state;
    const { tag } = this.state;
    page++;
    this.setState({ page });
    this.updateData(tag, page);
  };

  updateData = (tag = null, page = 0) => {
    const queryRequest = { limit: LIMIT };

    const { history, location } = this.props;
    const { pathname } = location;
    const query = {};
    if (tag) {
      queryRequest.tag = tag;
      query.tag = tag;
    }
    if (page) {
      queryRequest.page = page;
    }
    history.push({
      pathname,
      search: query ? queryString.stringify(query) : '',
    });
    const articles = globalStore.get('articles_list', []);
    Articles.initialData(api, queryRequest, globalStore).then(() => {
      globalStore.set(
        'articles_list',
        articles.concat(globalStore.get('articles_list', []))
      );
      this.setState({
        articles_list: globalStore.get('articles_list'),
        articles_categories: globalStore.get('articles_categories'),
      });
    });
  };

  render() {
    const {
      tag,
      likes,
      articles_list = [],
      articles_categories = [],
    } = this.state;
    if (!articles_list) {
      return null;
    }
    const isLast = Articles.isLast(articles_list);
    const {
      location: { pathname },
      t,
    } = this.props;
    const pageName = t('Articles.articlesTitle');
    return (
      <PageContainer>
        <CustomHelmet title={pageName} type="article" />
        <Breadcrumbs
          links={[
            {
              text: pageName,
            },
          ]}
        />
        <PageTitle>{pageName}</PageTitle>
        <FilterTags
          changeQuery={this.changeQuery}
          filters={articles_categories}
          selectedTag={tag}
        />
        <div className={styles.articlesCardContainer}>
          {articles_list.map(
            ({
              announcement_text,
              title,
              images = [],
              publication_date,
              category_id,
              id,
              text_id,
            }) => {
              const finded =
                articles_categories.find(
                  (article) => article.id === category_id
                ) || {};
              const { text_id: findedTag, name } = finded;
              return (
                <ArticleCard
                  key={id}
                  {...{
                    announcement_text,
                    title,
                    images,
                    publication_date,
                    findedTag,
                    name,
                    likes,
                    id,
                    text_id,
                    pathname,
                    type: 'articles',
                  }}
                />
              );
            }
          )}
        </div>
        {!isLast && (
          <div className={styles.showMoreButtonWrapper}>
            <button
              onClick={() => this.next()}
              type="button"
              className={styles.showMoreButton}
            >
              {t('localization.showMore')}
            </button>
          </div>
        )}
        <CustomText />
      </PageContainer>
    );
  }
}

export default ssr(Articles);
