import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { IMenuElement } from '@entities/menu';
import { SmartLink } from '@shared/ui/smart-link';
import styles from './mobileNavigation.module.scss';

function MobileNavigation({ menuList }: { menuList: IMenuElement[] }) {
  const navRef = useRef<HTMLUListElement>(null);
  const activeLinkRef = useRef<HTMLLIElement>(null);
  const { pathname } = useLocation();

  const scroll = (nav: HTMLElement, link: HTMLElement) => {
    const halfScreenWidth = window.innerWidth / 2;
    const halfLinkWidth = link.offsetWidth / 2;
    const center = link.offsetLeft - halfScreenWidth + halfLinkWidth;

    nav.scrollTo({
      left: center,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const header = document.getElementById('main-header');
    const container = document.getElementById('header-navigation');
    if (header && container) {
      header.addEventListener('wheel', (event) => {
        event.preventDefault();
        container.scrollLeft += event.deltaY;
      });
    }
  }, []);

  useEffect(() => {
    const mobileNav = navRef.current;
    const activeLink = activeLinkRef.current;

    if (mobileNav && activeLink) {
      scroll(mobileNav, activeLink);
    }
  }, [pathname]);

  const wordsPathname = pathname.split('/');
  return (
    <ul
      id="header-navigation"
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
      className={styles.list}
      ref={navRef}
    >
      {menuList
        .filter(({ visible }) => visible === true)
        .map(({ text_id, title }) => {
          const isActive = wordsPathname.includes(text_id);
          return (
            <li
              ref={isActive ? activeLinkRef : null}
              className={styles.listItem}
              key={text_id}
            >
              <SmartLink
                href={`/menu/${text_id}`}
                className={styles.link}
                activeClassName={styles.active}
              >
                {title}
              </SmartLink>
            </li>
          );
        })}
    </ul>
  );
}

export default MobileNavigation;
