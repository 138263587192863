import { useTranslation } from 'react-i18next';
import { currencies } from '@shared/config/currencies';
import clsx from 'clsx';
import {
  IUserOrder,
  IPreparetedOrderItem,
  OrderStatus,
} from '../../model/types';
import { ActiveOrderItem } from './ActiveOrderItem';
import styles from './activeOrder.module.scss';
import { addToppingsToOrderItems } from '../../model/addToppingsToOrderItems';

interface IActiveOrder {
  mainDomain: string;
  items: IPreparetedOrderItem[];
  order?: IUserOrder;
}

export function ActiveOrder({ order, mainDomain, items }: IActiveOrder) {
  const { t } = useTranslation();

  if (!order) return null;

  const { status_id, hash, total, total_dlv, is_pickup, currency } = order;

  const getOrderStatus = (statusId: OrderStatus, isPickup: boolean) => {
    switch (statusId) {
      case OrderStatus.Accepted:
        return t('OrderList.accepted');
      case OrderStatus.PreparingStart:
      case OrderStatus.PreparingEnd:
        return t('OrderList.preparing');
      case OrderStatus.Delivering:
        return isPickup
          ? t('OrderInfo.pickup_done_2')
          : t('OrderList.delivery');
      default:
        return '';
    }
  };

  const statusMessage = getOrderStatus(status_id, is_pickup);

  const itemsWithToppings = addToppingsToOrderItems(items);
  const itemDiscount = itemsWithToppings.filter((item) => item.price === 0);
  const itemPrice = itemsWithToppings.filter((item) => item.price !== 0);
  const totalSum = itemsWithToppings.reduce((sum, item) => sum + item.price, 0);

  const symbolCurrency = currencies[currency];

  return (
    <>
      <span className={styles.orderWord}>{t('OrderList.order')}</span>{' '}
      <span className={styles.hash}>№ {hash}</span>
      <span className={styles.status}>{statusMessage}</span>
      <div className={styles.orderList}>
        {itemPrice.map((item) => (
          <ActiveOrderItem
            item={item}
            currency={symbolCurrency}
            mainDomain={mainDomain}
            key={item.id}
          />
        ))}
        {itemDiscount.length > 0 && (
          <div className={styles.discount}>{t('OrderList.gift_items')}</div>
        )}
        {itemDiscount.map((item) => (
          <ActiveOrderItem
            item={item}
            currency={symbolCurrency}
            mainDomain={mainDomain}
            key={item.id}
          />
        ))}
      </div>
      {totalSum > 0 && (
        <>
          <div className={clsx(styles.sumContainer, styles.delivery)}>
            <div className={styles.description}>
              {t('OrderList.delivery_amount')}:
            </div>
            <div>
              {total_dlv}&nbsp;{symbolCurrency}
            </div>
          </div>
          <div className={clsx(styles.sumContainer, styles.total)}>
            <div className={styles.description}>
              {t('OrderList.total_sum')}:
            </div>
            <div>
              {total + total_dlv}&nbsp;{symbolCurrency}
            </div>
          </div>
        </>
      )}
    </>
  );
}
