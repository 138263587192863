import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import { InternalImage } from '@shared/ui/image';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import { useCheckCountries } from '@entities/countries';
import styles from './styles.module.scss';
import WokConstructorImage from './assets/wokConstructor.png';
import uaWokConstructorImage from './assets/uaWokConstructor.png';

export function WokConstructorCard({ action }: { action: () => void }) {
  const { t } = useTranslation();
  const { isUkraine } = useCheckCountries();
  const wokImage = isUkraine ? uaWokConstructorImage : WokConstructorImage;
  return (
    <CardWrapper className={styles.wrapper}>
      <InternalImage
        src={wokImage}
        alt={t('CardWok.prepared_wok')}
        width={400}
        height={400}
        className={styles.img}
        isCenter
      />
      <div className={styles.btnContainer}>
        <Button isFullWidth className={styles.btn} onClick={action}>
          {`${t('CardWok.createWok')}!`}
        </Button>
      </div>
    </CardWrapper>
  );
}
