import './ErrorComponent.scss';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import globalStore from 'modules/global-store';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { SmartLink } from '@shared/ui/smart-link';

const HamsterGame = loadable(
  () => import('@features/hamster-game').then((i) => i),
  {
    ssr: false,
  }
);
class ErrorComponent extends Component {
  constructor(props) {
    super(props);

    globalStore.put('is404', true, true); // For Server Side Rendering
  }

  componentDidMount() {
    globalStore.put('is404', true, true); // For Client Side Rendering
  }

  componentWillUnmount() {
    globalStore.put('is404', undefined, true); // For Client Side Rendering
  }

  render() {
    const { t, action_text, action_link } = this.props;
    const linkTo = action_link || '/menu';
    const linkText = action_text || t('ErrorComponent.Go to menu');

    return (
      <div className="error-container">
        <Helmet title="404" />
        <h2 className="error-container__header">404</h2>
        <p className="error-container__message">
          {t('ErrorComponent.Page does not exist')}
        </p>
        <div className="error-container__link">
          <div className="error-container__link--container">
            <SmartLink href={linkTo} className="link">
              {linkText}
            </SmartLink>
          </div>
        </div>
        <HamsterGame />
      </div>
    );
  }
}

export default withTranslation()(ErrorComponent);
