import { Component } from 'react';
import api from 'modules/helpers/api';
import * as session from 'modules/session';
import phoneValidator from 'modules/mask/phoneValidator';
import { useTranslation } from 'react-i18next';
import codes from 'modules/response/error-codes';
import { analytics } from 'frontend/analytics/analytics';
import { userEmitter } from 'modules/event-emitters';
import useUserIsExist from 'frontend/data/user/useUserExist';
import { clsx } from 'clsx';
import { Button } from '@shared/ui/button';
import { useCheckCountries } from '@entities/countries';
import { SmartLink } from '@shared/ui/smart-link';
import { ModalParagraph } from '@shared/ui/modal-paragraph';
import Emitter from '../../../Emitters';
import SmsTimer from '../../../../../helpers/SmsTimer';
import GetCode from '../../../../Phone/GetCode/GetCode';
import Masked from '../../../../Masked';
import styles from './registerOrAuth.module.scss';
// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';

class RegisterOrAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value.phone : undefined,
      isPhoneCorrect: false,
      codeError: null,
      smsTimerValue: 0,
      viewMode: props.view || props.info, // default, bonus, profile, social
      checkedInfo: true,
      isBonusReceived: false,
      isButtonPressed: false,
      handleAfterModal: props.value ? props.value.handleAfterModal : undefined,
      // isDelivery: props.value ? props.value.isDelivery : undefined,
    };
  }

  componentDidMount() {
    const value = this.inputPhone ? this.inputPhone.value : '';
    userEmitter.addListener('User.GetCode.Response', this.changeHandler);
    userEmitter.addListener('User.CodeAlreadySent', this.changeTimer);
    let processPhone = localStorage.getItem('processPhone') || '';
    let isPhoneCorrect = this.checkPhone(processPhone);
    this.smsTimer = SmsTimer.getInstance('SMS_REGISTRATION_CODE');

    if (processPhone) {
      localStorage.removeItem('processPhone');
      processPhone = '';
      isPhoneCorrect = false;
    }

    this.setState({
      value: value || processPhone,
      isPhoneCorrect,
      isCodeSend: this.smsTimer.secondsToEnd > 0 && processPhone,
    });
  }

  componentWillUnmount() {
    userEmitter.removeListener('User.GetCode.Response', this.changeHandler);
    userEmitter.removeListener('User.CodeAlreadySent', this.changeTimer);
    this.smsTimer.off('tick', this.onSmsTimerTick);
    this.close();
  }

  handleButtonPress = () => {
    this.setState({ isButtonPressed: true });
  };

  onInputPhone = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      value,
      isCodeSend: false,
    });
  };

  onSmsTimerTick = (value) => {
    this.setState({
      smsTimerValue: value,
    });
    if (value === 0) {
      this.setState({ isButtonPressed: false });
    }
  };

  changeHandler = ({ code, data }) => {
    const time = data ? data.ttl : 0;
    this.smsTimer = SmsTimer.getInstance('SMS_REGISTRATION_CODE', time);
    this.smsTimer.on('tick', this.onSmsTimerTick);

    if (code === 200) {
      this.setState({
        isCodeSend: true,
      });
    }
  };

  changeTimer = ({ data }) => {
    const time = data.errors.ttl;
    localStorage.removeItem('SMS_REGISTRATION_CODE');
    this.smsTimer = SmsTimer.getInstance('SMS_REGISTRATION_CODE', time);
    this.smsTimer.on('tick', this.onSmsTimerTick);
  };

  checkUserBonus = async ({ target }) => {
    target.disabled = true;
    const { value: phone, viewMode } = this.state;
    const { t } = this.props;
    const { value } = this.input;
    if (!value || !phone) {
      this.setState({ codeError: { message: '' } });
      target.disabled = false;
      return;
    }
    const form = { phone, code: value };

    try {
      const { user } = await api('user.register_or_auth', form);
      this.props.setUserExist(true);
      target.disabled = false;
      if (user && Object.keys(user).length) {
        session.set('user', user);
        userEmitter.emit('User.Change');
        userEmitter.emit('User.Login.Event');
        analytics.deliveryFieldsAction('phone');
        const { addresses } = user;
        if (addresses?.length) {
          analytics.addressAction();
        }
      }
      const { cart = {} } = await api('cart.refresh');
      const { phone_bonuses: { discount, gold_discount } = {} } = cart;
      session.set('cart', cart);
      Emitter.emit('CART_CHANGE', { notChangeUrl: true });
      localStorage.removeItem('processPhone');
      if (viewMode === 'bonus') {
        this.setState({
          isBonusReceived: true,
          discount,
          gold_discount,
        });
      }
      return true;
    } catch (e) {
      const { data = {} } = e;
      const { code } = data;
      if (code) {
        switch (code) {
          case codes.SERVICE1C_INVALID:
            if (viewMode === 'bonus') {
              Emitter.emit('MODAL_CALL', {
                modal: 'ErrorCartModal',
                value: t('locale.for_your_phone_no_bonuses'),
              });
            }
            break;
          case codes.PHONE_CODE_INVALID:
          case codes.ORDER_PHONE_PAY_CODE_INVALID:
            this.setState({ codeError: { message: 'code_invalid' } });
            break;
          default:
        }
      }
      return false;
    } finally {
      target.disabled = false;
    }
  };

  checkPhone = (processPhone) => {
    const value = this.inputPhone ? this.inputPhone.value : '';
    if (processPhone && !value) {
      return phoneValidator.isValid(processPhone);
    }
    return phoneValidator.isValid(value);
  };

  toggleShow = () => {
    this.setState((prevState) => ({
      checkedInfo: !prevState.checkedInfo,
    }));
  };

  close = () => {
    Emitter.emit('MODAL_CALL', { modal: false });
  };

  acceptDiscount = () => {
    analytics.discountDesire();
    this.close();
  };

  handleValidate = (value) => {
    this.setState({
      isPhoneCorrect: value,
    });
  };

  render() {
    const {
      isCodeSend,
      isButtonPressed,
      isBonusReceived,
      discount,
      gold_discount,
      value,
      isPhoneCorrect,
      smsTimerValue,
      viewMode,
      codeError,
      checkedInfo,
      handleAfterModal,
      // isDelivery,
    } = this.state;
    const { t, isUkraine } = this.props;
    const isDisabled = isButtonPressed || smsTimerValue > 0;
    return (
      <>
        {viewMode === 'bonus' && (
          <>
            <ModalParagraph>
              {isBonusReceived && discount
                ? `${t('locale.your_discount')} ${discount}%`
                : isBonusReceived && !discount
                ? `${t('locale.no_discount')}`
                : t('locale.sms_verification')}
            </ModalParagraph>
            {isBonusReceived && discount ? (
              gold_discount ? (
                ''
              ) : (
                <div className={styles.textRow}>
                  <p>
                    {t(
                      'locale.does_not_apply_to_products_involved_in_the_stock'
                    )}
                  </p>
                </div>
              )
            ) : isBonusReceived && !discount ? (
              <div className={styles.textRow}>
                <p>
                  {t('locale.more_about_promo')}{' '}
                  <SmartLink href="/akcii" target="_blank">
                    {t('locale.special_offers_section')}
                  </SmartLink>
                  .
                </p>
              </div>
            ) : (
              <div className={styles.inputRow}>
                <label htmlFor="">
                  {t('locale.is_discount_bind_to_this_number')}
                </label>
                <Masked
                  type="phone"
                  defaultValue={value}
                  patterns={{ '#': /[0-9]/ }}
                  onChange={this.onInputPhone}
                  onPaste={this.onInputPhone}
                  onKeyDown={this.onInputPhone}
                  onValidate={this.handleValidate}
                  required
                  forwardRef={(input) => (this.inputPhone = input)}
                />
              </div>
            )}
            {isCodeSend && !isBonusReceived ? (
              <div className={styles.inputRow}>
                <div className={styles.codeWrap}>
                  <label className={codeError ? styles.invalid : ''}>
                    {codeError && codeError.message
                      ? t(`RegisterOrAuth.${codeError.message}`)
                      : t('locale.the_code_from_sms')}
                  </label>
                  <GetCode
                    tagName="span"
                    phone={value}
                    handleButtonPress={() => this.handleButtonPress()}
                    className={styles.recoveryControl}
                    value={`${t('RegisterOrAuth.repeat_request')} ${
                      smsTimerValue > 0 ? smsTimerValue : ''
                    }`}
                    disabled={isDisabled}
                  />
                </div>
                <input
                  type="number"
                  pattern="[0-9]*"
                  min="0"
                  ref={(input) => (this.input = input)}
                />
              </div>
            ) : undefined}
            <div className={modalStyles.btnContainer}>
              {!isBonusReceived ? (
                isCodeSend ? (
                  <Button
                    isFullWidth
                    onClick={(target) => {
                      const success = this.checkUserBonus(target);
                      if (handleAfterModal) handleAfterModal({ success });
                      if (viewMode !== 'bonus' && success) this.close();
                    }}
                  >
                    {t('locale.сheck')}
                  </Button>
                ) : (
                  <GetCode
                    tagName="button"
                    phone={value}
                    className={clsx('sw-button', styles.swButton, {
                      ua: isUkraine,
                    })}
                    checkedInfo={checkedInfo}
                    handleButtonPress={() => this.handleButtonPress()}
                    value={`${t('RegisterOrAuth.send_code')} ${
                      smsTimerValue > 0 ? smsTimerValue : ''
                    }`}
                    type="button"
                    disabled={isDisabled}
                  />
                )
              ) : isBonusReceived && !discount ? (
                <Button isFullWidth onClick={this.close}>
                  {t('Info.OK')}
                </Button>
              ) : (
                <Button isFullWidth onClick={this.acceptDiscount}>
                  {t('locale.good')}
                </Button>
              )}
            </div>
          </>
        )}

        {viewMode !== 'bonus' && (
          <>
            <ModalParagraph>
              {' '}
              {viewMode === 'default' || viewMode === 'profile'
                ? t('RegisterOrAuth.login_or_registration')
                : ''}
            </ModalParagraph>

            <div className={styles.inputRow}>
              <label htmlFor="register-phone">
                {t('RegisterOrAuth.enter_phone')}*
              </label>
              <Masked
                id="register-phone"
                type="phone"
                defaultValue={value}
                patterns={{ '#': /[0-9]/ }}
                onChange={this.onInputPhone}
                onPaste={this.onInputPhone}
                onKeyDown={this.onInputPhone}
                onValidate={this.handleValidate}
                refCallback={this.setRef}
                required
                forwardRef={(input) => (this.inputPhone = input)}
              />
            </div>

            {!isCodeSend && !isBonusReceived && (
              <>
                <div className={clsx('auth-register__checkbox')}>
                  <div className="checkbox-box">
                    <div
                      className={clsx('checkbox-box__checkbox-container', {
                        error: !checkedInfo,
                        ua: isUkraine,
                      })}
                    >
                      <input
                        type="checkbox"
                        id="info"
                        name="info_personal"
                        checked={checkedInfo}
                        onChange={this.toggleShow}
                      />
                      <label htmlFor="info">{t('RegisterOrAuth.info')}</label>
                    </div>
                  </div>
                </div>
                <div className={modalStyles.btnContainer}>
                  <GetCode
                    tagName="button"
                    phone={value}
                    className={clsx('sw-button', styles.swButton, {
                      ua: isUkraine,
                    })}
                    checkedInfo={checkedInfo}
                    handleButtonPress={() => this.handleButtonPress()}
                    value={`${t('RegisterOrAuth.send_code')} ${
                      smsTimerValue > 0 ? smsTimerValue : ''
                    }`}
                    type="button"
                    disabled={
                      isButtonPressed ||
                      smsTimerValue > 0 ||
                      !checkedInfo ||
                      !isPhoneCorrect
                    }
                  />
                </div>
              </>
            )}
            {isPhoneCorrect && isCodeSend && !isBonusReceived && (
              <>
                <div className={styles.inputRow}>
                  <div className={styles.codeWrap}>
                    <label className={codeError ? styles.invalid : ''}>
                      {codeError && codeError.message
                        ? t(`RegisterOrAuth.${codeError.message}`)
                        : t('locale.the_code_from_sms')}
                    </label>
                    <GetCode
                      tagName="span"
                      phone={value}
                      className={styles.recoveryControl}
                      handleButtonPress={() => this.handleButtonPress()}
                      value={`${t('RegisterOrAuth.repeat_request')} ${
                        smsTimerValue > 0 ? smsTimerValue : ''
                      }`}
                      disabled={isDisabled}
                    />
                  </div>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    ref={(input) => (this.input = input)}
                  />
                </div>
                <div className={modalStyles.btnContainer}>
                  <Button
                    isFullWidth
                    type="button"
                    onClick={async (target) => {
                      const success = await this.checkUserBonus(target);
                      if (handleAfterModal) handleAfterModal({ success });
                      if (viewMode !== 'bonus' && success) this.close();
                    }}
                  >
                    {t('RegisterOrAuth.login')}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const withUserExistHook = (Component) => {
  return function (props) {
    const { t } = useTranslation();
    const { setUserExist } = useUserIsExist();
    const { isUkraine } = useCheckCountries();
    return (
      <Component
        {...props}
        setUserExist={setUserExist}
        t={t}
        isUkraine={isUkraine}
      />
    );
  };
};
export default withUserExistHook(RegisterOrAuth);
