import { useEffect } from 'react';
import { useQuery } from '@shared/lib/query';
import { resetCart } from '../api';

export const useResetCartOnChangeCity = (hasCityChanged: boolean) => {
  const params = useQuery();
  const hasProductOrPromocodeParam =
    params.has('product') || params.has('promocode');
  useEffect(() => {
    // из за src/server/middleware/calculate-by-query-params.ts  блокируем сброс корзины
    if (hasProductOrPromocodeParam) return;
    if (hasCityChanged) {
      resetCart();
    }
  }, [hasCityChanged, hasProductOrPromocodeParam]);
};
