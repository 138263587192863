import './Vacancies.scss';
import { Component } from 'react';
import { vacanciesEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/page-container';
import { PageTitle } from '@shared/ui/page-title';
import { VacancyVideo } from '../../vacancy-video';
import VacanciesModal from '../../../../Components/Vacancies/VacanciesModal/VacanciesModal';
import VacanciesInfo from '../../../../Components/Vacancies/VacancyInfo/VacanciesInfo';
import VacancyContactsLine from '../../../../Components/Vacancies/VacancyContactsLine/VacancyContactsLine';
import VacancyCommentLine from '../../../../Components/Vacancies/VacancyCommentLine/VacancyCommentLine';
import VacancyCard from '../../../../Components/Vacancies/VacancyCard/VacancyCard';
import VacancyCardMobile from '../../../../Components/Vacancies/VacancyCard/VacancyCardMobile';
import VacancyCommentLineMobile from '../../../../Components/Vacancies/VacancyCommentLine/VacancyCommentLineMobile';
import VacanciesInfoMobile from '../../../../Components/Vacancies/VacancyInfo/VacanciesInfoMobile';
import VacanciesContactLineMobile from '../../../../Components/Vacancies/VacancyContactsLine/VacancyContactsLineMobile';
import VacanciesModalMobile from '../../../../Components/Vacancies/VacanciesModal/VacanciesModalMobile';
import CustomText from '../../../../Components/CustomText/CustomText';
import CustomHelmet from '../../../../Components/CustomHelmet';

class Vacancies extends Component {
  static LOADED_KEY = 'vacancies_loaded';

  static initialData = (fetch, params, globalStore) => {
    return fetch('vacancies')
      .then((vacancies) => globalStore.set('vacancies', vacancies))
      .catch((e) => {
        console.error('Vacancies', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      cellInRow: 3,
      vacancies: globalStore.get('vacancies'),
    };
  }

  componentDidMount() {
    if (globalStore.get(Vacancies.LOADED_KEY)) {
      globalStore.unlink(Vacancies.LOADED_KEY);
    } else {
      Vacancies.initialData(api, {}, globalStore).then(() => {
        this.setState({
          vacancies: globalStore.get('vacancies', []),
        });
      });
    }

    vacanciesEmitter.addListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay
    );
    vacanciesEmitter.addListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay
    );

    window.addEventListener('resize', this.checkSize);
    window.addEventListener('load', this.checkSize);
  }

  componentWillUnmount() {
    vacanciesEmitter.removeListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay
    );
    vacanciesEmitter.removeListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay
    );

    window.removeEventListener('resize', this.checkSize);
    window.removeEventListener('load', this.checkSize);
  }

  getTitle() {
    const { t } = this.props;
    return t('Vacancies.vacancies_title');
  }

  checkSize = () => {
    const documentWidth = document.documentElement.offsetWidth;

    if (documentWidth < 1200) return this.setState({ cellInRow: 0 });
    if (documentWidth > 1200) return this.setState({ cellInRow: 3 });
  };

  showOverlay = () => {
    // from mobile page
    // document.body.style.overflow = 'hidden';

    this.setState({
      showOverlay: true,
    });
  };

  hideOverlay = () => {
    // from mobile page
    // document.body.style.overflow = '';

    this.setState({
      showOverlay: false,
    });

    vacanciesEmitter.emit('Vacancies.Card.Unrotate');
  };

  renderMobile() {
    const { vacancies } = this.state;
    const { t } = this.props;
    const current_city = globalStore.get('current_city');
    const { vacancy_email } = current_city || {};

    const vacanciesFirst = vacancies.slice(0, 3);
    const vacanciesLast = vacancies.slice(3);

    const contentFirst = [];
    const contentLast = [];

    vacanciesFirst.forEach((vacancy) => {
      contentFirst.push(
        <VacancyCardMobile
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          key={vacancy.id}
        />
      );
    });

    vacanciesLast.forEach((vacancy) => {
      contentLast.push(
        <VacancyCardMobile
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          key={vacancy.id}
        />
      );
    });

    return (
      <PageContainer>
        <CustomHelmet title={this.getTitle()} type="article" />
        <PageTitle isCenterOnMobile>{t('Vacancies.vacancies_title')}</PageTitle>
        <div className="vacancies-component">
          <div>
            <div>
              <div className="vacancies-component__list">{contentFirst}</div>
              <VacancyCommentLineMobile
                key="comment-text-1"
                shortname="comment1"
              />
              <div className="vacancies-component__list">{contentLast}</div>
            </div>
            <VacanciesInfoMobile />
            <VacancyVideo />
            <VacanciesContactLineMobile vacancyEmail={vacancy_email} />
            <VacanciesModalMobile />
          </div>
        </div>
        <CustomText />
      </PageContainer>
    );
  }

  render() {
    const { showOverlay, cellInRow, vacancies } = this.state;
    const { t } = this.props;
    const { vacancy_email } = globalStore.get('current_city');

    if (!vacancies) {
      return <div />;
    }

    if (session.get('isMobile')) {
      return this.renderMobile();
    }

    const vacanciesFirst = vacancies.slice(0, 3);
    const vacanciesLast = vacancies.slice(3);

    const contentFirst = [];
    const contentLast = [];

    vacanciesFirst.forEach((vacancy, index) => {
      contentFirst.push(
        <VacancyCard
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          index={index}
          key={vacancy.id}
        />
      );
    });

    vacanciesLast.forEach((vacancy, index) => {
      contentLast.push(
        <VacancyCard
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          index={index + vacanciesFirst.length}
          key={vacancy.id}
        />
      );
    });

    return (
      <PageContainer>
        <CustomHelmet title={this.getTitle()} type="article" />
        <Breadcrumbs
          links={[
            {
              text: t('Vacancies.vacancies_title'),
            },
          ]}
        />
        <PageTitle>{t('Vacancies.vacancies_title')}</PageTitle>
        <div className="vacancies-component">
          {cellInRow ? (
            <div>
              <div className="vacancies-component__list">{contentFirst}</div>
              <VacancyCommentLine key="comment-text-1" shortname="comment1" />
              <div className="vacancies-component__list">{contentLast}</div>
            </div>
          ) : (
            <div>
              <div className="vacancies-component__list">
                {contentFirst}
                {contentLast}
              </div>
            </div>
          )}
          <div
            className={`vacancies-component__overlay ${
              showOverlay ? 'vacancies-component__overlay--shown' : ''
            }`}
            onClick={this.hideOverlay}
            role="none"
          />
          <VacanciesInfo />
          <VacancyVideo />
          <VacancyContactsLine vacancyEmail={vacancy_email} />
          <VacanciesModal />
        </div>
        <CustomText />
      </PageContainer>
    );
  }
}

export default ssr(Vacancies);
