import { currencies } from '@shared/config/currencies';
import { IImage } from 'types/index';

export interface ITopping {
  title: string;
  qty: number;
  price: number;
}

export interface IUserOrderItem {
  id: number;
  order_id: number;
  product_id: number;
  price: number;
  images: IImage[];
  parent_id: number | null;
  qty: number;
  title: string;
}

export interface IPreparetedOrderItem extends IUserOrderItem {
  productUrl: string;
  toppings?: ITopping[];
}

export enum OrderStatus {
  Accepted = 1,
  PreparingStart = 2,
  PreparingEnd = 3,
  Delivering = 4,
  Delivered = 5,
  Canceled = 6,
}

export interface IUserOrder {
  address: {
    apartment: null | string;
    building: string;
    city: string;
    district: null | string;
    entrance: null | string;
    floor: null | string;
    full: null | string;
    house: null | string;
    street: null | string;
  };
  bonus_phone: string;
  change_from: null;
  click_id: null;
  client: string;
  comment: string;
  courier_tips: null;
  cpa_pixel: string;
  created_at: string;
  currency: keyof typeof currencies;
  cutlery_qty: number;
  delivery_date: null;
  delivery_date_last: null;
  delivery_with_card: string;
  delivery_without_card: string;
  email: string;
  fallback_sended: boolean;
  hash: string;
  http_referer: null;
  is_notified: boolean;
  is_pickup: boolean;
  is_sync: boolean;
  items: IUserOrderItem[];
  latitude: number;
  longitude: number;
  name: string;
  order_id: null;
  partner_id: null;
  payment_status: null;
  payment_success_at: null;
  payment_type: string;
  phone: string;
  phone_hashed: string;
  promo_code: null;
  promo_code_params: string;
  push_device_id: string;
  push_device_type: null;
  salt: string;
  short_hash: string;
  status: string;
  status_id: OrderStatus;
  store_address: string;
  store_id: number;
  sync_id: null;
  synced_at: string;
  total: number;
  total_dlv: number;
  transaction_id: null;
  updated_at: string;
  user_discount: number;
  user_id: number;
  utm: null;
  viewed: boolean;
  wm_id: null;
}

export interface IUserAddress {
  id: number;
  user_id: number;
  latitude: string;
  longitude: string;
  address: string;
  city: string;
  street: string;
  house: string;
  building: string;
  entrance: string;
  apartment: string;
  floor: number;
  title: string | null;
  used_at: string;
}
