import { MediaContextProvider } from '@shared/lib/media';
import { ErrorBoundary } from 'react-error-boundary';
import Error from '@shared/ui/error';
import { RoutesWithSubRoutes } from '@shared/lib/routes-with-sub-routes';
import routes from './routes';

function App() {
  return (
    <MediaContextProvider>
      <ErrorBoundary FallbackComponent={Error}>
        <RoutesWithSubRoutes routes={routes} />
      </ErrorBoundary>
    </MediaContextProvider>
  );
}

export default App;
