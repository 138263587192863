import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { baseApi } from '@shared/api';
import { persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rootReducer } from './rootReducer';

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: unknown) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window === 'undefined'
    ? createNoopStorage()
    : createWebStorage('local');

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['complectation', 'pickupStore'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = (
  initialState?: PreloadedState<ReturnType<typeof rootReducer>>
) => {
  const store = configureStore({
    preloadedState: initialState || {},
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(baseApi.middleware),
  });

  setupListeners(store.dispatch);
  return store;
};

export const initStore = makeStore();
