import Template from 'frontend/Pages/Template/Template';
import { Header } from '@widgets/header';
import MobileMenu from 'frontend/Components/MainMenu/MobileMenu/MobileMenu';
import { Footer } from '@widgets/footer';
import {
  IRoute,
  RoutesWithSubRoutes,
} from '@shared/lib/routes-with-sub-routes';
import { useResetCartOnChangeCity } from '@features/cart-reset';
import { useCartQtyHandler } from '@features/add-product-to-cart/useCartQtyHandler';
import { useSelectAddressFirst } from '@features/modals/select-address-first';
import { useCityChange, useSelectCity } from '@entities/cities';
import { useFirstShowConfirmCityModal } from '@features/modals/confirm-city-modal';
import { useSelectCityModal } from '@features/modals/select-city-modal';
import { YMapsProvider } from '@app/providers/ymaps-provider';
import SupportChat from '@widgets/support-chat/SupportChat';
import Cookie from '@widgets/cookie/Cookie';
import { SidebarDesktop } from '@widgets/sideBar';
import { DesktopMenu, useSelectMenu } from '@entities/menu';
import {
  useCheckCountries,
  useSelectCountry,
  useSelectCountryMainDomain,
} from '@entities/countries';
import { ModalProvider } from './providers/modal-provider';
import { AppHelmet, CountryHelmet } from './helmet';
import TemplateHelmet from './helmet/TemplateHelmet';
// import Hamster from 'frontend/@widgets/hamster/Hamster';

export function CityLayout({ routes }: { routes: IRoute[] }) {
  const { hasCityChanged } = useCityChange();
  useResetCartOnChangeCity(hasCityChanged);
  const selectCityModal = useSelectCityModal();
  useFirstShowConfirmCityModal(selectCityModal.show);
  useCartQtyHandler();
  useSelectAddressFirst();
  const mainDomain = useSelectCountryMainDomain();
  const city = useSelectCity();
  const country = useSelectCountry();
  const isCheckCountries = useCheckCountries();
  const menuList = useSelectMenu();
  return (
    <YMapsProvider>
      <ModalProvider>
        <AppHelmet
          mainDomain={mainDomain}
          formatLocale={city.format_locale}
          countryName={country.name}
          cityName={city.city_name}
          cityPhone={city.phone}
          cityTextId={city.text_id}
          worktimeFrom={city.info.worktime.from}
          worktimeTo={city.info.worktime.to}
        />
        <CountryHelmet {...isCheckCountries} />
        <TemplateHelmet />
        <Header
          menuList={menuList}
          countryId={country.id}
          isCheckCountries={isCheckCountries}
        />
        <SidebarDesktop>
          <DesktopMenu mainDomain={mainDomain} menuList={menuList} />
        </SidebarDesktop>
        <MobileMenu />
        <RoutesWithSubRoutes routes={routes} />
        <Footer {...isCheckCountries} />
        <Template />
        {/* <Hamster /> */}
        <Cookie />
        {city.chat_enabled && <SupportChat {...isCheckCountries} />}
      </ModalProvider>
    </YMapsProvider>
  );
}
