import { useRef } from 'react';
import { useMemorizer } from '@shared/lib/memorizer';
import { useSelectCity } from '../model/selectors';
import { cityTextIdStorageName } from '../constants';

export function useCityChange() {
  const confirmCityMemorizer = useMemorizer(cityTextIdStorageName);
  const currentCity = useSelectCity();
  const hasCityChanged = useRef(false);
  if (typeof window === 'undefined' || !currentCity) {
    return { hasCityChanged: hasCityChanged.current };
  }
  const cityIdFromStorage = localStorage.getItem('currentCityTextId');
  if (!cityIdFromStorage) {
    localStorage.setItem('currentCityTextId', currentCity.text_id);
  } else if (cityIdFromStorage !== currentCity.text_id) {
    localStorage.setItem('currentCityTextId', currentCity.text_id);
    confirmCityMemorizer.set(currentCity.text_id);
    hasCityChanged.current = true;
  }
  return { hasCityChanged: hasCityChanged.current };
}
