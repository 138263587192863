import { PropsWithChildren } from 'react';
import { ExternalImage } from '@shared/ui/image';
import { useTranslation } from 'react-i18next';
import { useSelectCountryMainDomain } from '@entities/countries';
import { SmartLink } from '@shared/ui/smart-link';
import styles from './trioItem.module.scss';
import { ITrioItem } from '../types/trioOnMainPage';

interface ITrioItemPage {
  item: ITrioItem;
}

function TrioItemLayout({
  item,
  children,
}: PropsWithChildren<{ item: ITrioItem }>) {
  const { link, has_static_page, text_id } = item;

  let itemLink = '';
  if (has_static_page) {
    itemLink = `/akcii/${encodeURIComponent(text_id)}`;
  } else if (link) {
    itemLink = link;
  }

  return itemLink ? (
    <SmartLink href={itemLink} key={item.id} className={styles.link}>
      {children}
    </SmartLink>
  ) : (
    <span key={item.id} className={styles.link}>
      {children}
    </span>
  );
}

function TrioItem({ item }: ITrioItemPage) {
  const { t } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const { images } = item;
  const [imageFile, imageFileSlide] = images;
  const imageSrc = imageFile?.filename;
  const imageSrcSlide = imageFileSlide?.filename;

  return (
    <TrioItemLayout item={item}>
      <div className={styles.externalImg}>
        <ExternalImage
          src={imageSrc}
          mainDomain={mainDomain}
          alt={t('Stocks.stock_image')}
          width={178}
          height={168}
        />
      </div>
      <div className={styles.internalImg}>
        <ExternalImage
          mainDomain={mainDomain}
          src={imageSrcSlide}
          alt={t('Stocks.stock_slide_image')}
          width={178}
          height={187}
        />
      </div>
    </TrioItemLayout>
  );
}

export default TrioItem;
