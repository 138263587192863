import { HeaderPhone } from '@widgets/header/headerPhone/HeaderPhone';
import { ProfileButton } from '@widgets/header/profileButton/ProfileButton';
import { SelectLanguage } from '@features/select-language';
import { SideBarOpener } from '@widgets/sideBar';
import { HorizontalHeaderMenu } from '@entities/header-menu';
import { useSelectCityModal } from '@features/modals/select-city-modal';
import { CurrentCityBtn } from '@entities/cities';
import { CartLink } from '../cartLink/CartLink';
import { PromotionsLink } from '../promotionsLink/PromotionsLink';
import styles from './desktop.module.scss';

function Desktop({
  decoration,
  logo,
}: {
  decoration: { left?: JSX.Element; right?: JSX.Element };
  logo: JSX.Element;
}) {
  const cityModal = useSelectCityModal();
  return (
    <>
      <div className={styles.leftBlock}>
        <SideBarOpener />
        {logo}
        <SelectLanguage />
        <CurrentCityBtn action={cityModal.show} />
        <HeaderPhone />
      </div>
      {decoration.left}
      <HorizontalHeaderMenu />
      {decoration.right}
      <div className={styles.rightBlock}>
        <ProfileButton />
        <PromotionsLink />
        <CartLink />
      </div>
    </>
  );
}

export { Desktop };
