import './Composition.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import { cardsEmitter, filterPanelEmitter } from 'modules/event-emitters';
import { replaceQuery, getQueryObject } from 'modules/router-query-utils';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import FilterCheckbox from '../Components/Checkbox/FilterCheckbox/FilterCheckbox';

class Composition extends Component {
  constructor(props) {
    super(props);
    const { compositions, checkedComps } = props;
    for (const item in compositions) {
      const { title } = compositions[item];
      compositions[item].title = title.charAt(0).toLowerCase() + title.slice(1);
    }
    this.state = {
      showModal: false,
      compositions,
      checkedCompositions: checkedComps || {},
    };
  }

  componentDidMount() {
    filterPanelEmitter.addListener('reset_all', this.resetComposition);
  }

  componentWillReceiveProps(props) {
    let { checkedComps } = props;
    const { compositions } = props;
    const checkedCompositionsObj = {};
    checkedComps.forEach((item) => {
      checkedCompositionsObj[item] = true;
    });

    checkedComps = checkedCompositionsObj;

    const mergedComp = {};
    const compKeys = Object.keys(compositions);

    for (const compositionId in checkedComps) {
      const finded = compKeys.find(
        (composition_id) => composition_id === compositionId
      );

      if (finded) {
        mergedComp[compositionId] = true;
      }
    }

    this.setState({
      compositions,
      checkedCompositions: mergedComp,
    });

    const oldComp = checkedComps;
    const newComp = mergedComp;

    const oldJson = JSON.stringify(oldComp);
    const newJson = JSON.stringify(newComp);

    if (oldJson !== newJson) {
      cardsEmitter.emit('ProductList.CheckedCompositions', mergedComp);
    }
  }

  componentWillUnmount() {
    filterPanelEmitter.removeListener('reset_all', this.resetComposition);
  }

  handleClick = () => {
    this.handleOnBlurMetrics();

    // eventBus.emitEvent('product_filter', [
    //   {
    //     type: 'composition',
    //   },
    // ]);

    const { compositions } = this.state;
    const compositionsCount = Object.keys(compositions).length;

    if (compositionsCount === 0) {
      return;
    }

    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  handleOutsideClick = ({ target }) => {
    if (this.node.contains && this.node.contains(target)) {
      return;
    }

    if (target.closest && target.closest('.sticky-menu__inner--icon')) {
      return;
    }

    this.handleClick();
  };

  @autobind
  handleOnBlurMetrics() {
    // eventBus.emitEvent('product_filter', [
    //   {
    //     type: 'composition',
    //   },
    // ]);
  }

  @autobind
  onToggleHandler({ name, checked }) {
    const { checkedCompositions } = this.state;
    const composition = JSON.parse(JSON.stringify(checkedCompositions));
    if (checked) {
      checkedCompositions[name] = true;
      composition[name] = true;
    } else {
      delete checkedCompositions[name];
      composition[name] = false;
    }

    const query = getQueryObject(composition);
    for (const key in query) {
      if (!query[key]) delete query[key];
    }

    replaceQuery(query);
    this.setState({ checkedCompositions });
    cardsEmitter.emit('ProductList.CheckedCompositions', checkedCompositions);
  }

  @autobind
  resetComposition() {
    cardsEmitter.emit('ProductList.CheckedCompositions', {});
    const query = getQueryObject({});

    for (const key in query) {
      if (!query[key]) delete query[key];
    }
    replaceQuery(query);

    setTimeout(() => {
      this.setState({
        checkedCompositions: {},
      });
    }, 300);
  }

  renderCompositionCheckboxes(compositions) {
    const compositionsKeys = Object.keys(compositions);
    const { checkedCompositions } = this.state;
    return compositionsKeys.map((compositionId) => {
      const { title } = compositions[compositionId];
      return (
        <FilterCheckbox
          title={title}
          name={compositionId}
          key={`comp-id=${compositionId}`}
          checked={checkedCompositions[compositionId]}
          onToggle={this.onToggleHandler}
        />
      );
    });
  }

  renderCompositions(compositions) {
    const { checkedCompositions, showModal } = this.state;
    const { t } = this.props;
    const checkedKeys = Object.keys(checkedCompositions);
    return (
      <div className="sort-ingredients__container">
        {showModal && (
          <div className="sort-ingredients__ingredient-tags">
            <div className="menu-list__composition-filters">
              {this.renderCompositionCheckboxes(compositions)}
            </div>
            {checkedKeys.length ? (
              <div className="btn-container">
                <Button onClick={this.resetComposition}>
                  {t('Composition.Reset_All')}
                </Button>
              </div>
            ) : undefined}
          </div>
        )}
        <div className="sort-ingredients__ingredient-tags__mobile">
          {this.renderCompositionCheckboxes(compositions)}
        </div>
      </div>
    );
  }

  render() {
    const { compositions, checkedCompositions } = this.state;
    const { t } = this.props;
    const compLength = Object.keys(checkedCompositions).length;
    return (
      <div
        className="sort-ingredients"
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          onClick={this.handleClick}
          className={`sort-ingredients__ingredients${
            Object.keys(compositions).length === 0
              ? ' sort-ingredients__ingredients--disabled'
              : ''
          }`}
        >
          {t('Composition.Ingredients')}{' '}
          {compLength ? ` (${compLength})` : '...'}
        </div>
        {Object.keys(compositions).length > 0 &&
          this.renderCompositions(compositions)}
      </div>
    );
  }
}

export default withTranslation()(Composition);
